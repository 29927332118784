import clsx from 'clsx';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { Chip, LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { divideSafely, round } from '@sightgain/core/calculations';
import { truncate } from '@sightgain/core/strings';
import { FrameworkItemWithScores, ScoreMeta } from '../../../../../../services/interfaces';
import { useAssessmentContext } from '../../../../components/AssessmentContext';
import FrameworkItemScores from '../../frameworkItemScores';
import { MaturityLevel } from '../../interface';

interface ItemProps {
  item: FrameworkItemWithScores;
  setCurrentItem: Dispatch<SetStateAction<FrameworkItemWithScores | null>> | ((item: FrameworkItemWithScores) => void);
}

const useStyles = makeStyles({
  bgRed: {
    background: 'rgba(248, 92, 92, 0.49)',
  },
  bgYellow: {
    background: 'rgba(255, 255, 0, 0.49)',
  },
  bgGreen: {
    background: 'rgba(0, 255, 177, 0.49)',
  },
  cell: {
    minWidth: 140,
    minHeight: 150,
    marginLeft: -1,
    marginTop: -1,
    borderRadius: 0,
    display: 'flex',
  },
  cellBackground: {
    flex: 1,
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.7)',
    height: '99%',
    minWidth: 119,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgb(255, 255, 255, 0.12)',
  },
  expandCell: {
    height: '100%',
    minWidth: 21,
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
  },
  progressBar: {
    background: 'rgba(255, 255, 255, 0.09)',
  },
  tileContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  tileText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    width: '100%',
    gap: '10px',
  },
});

export default function Item({ item, setCurrentItem }: ItemProps) {
  const classes = useStyles();
  const { currentOverallMaturityLevel } = useAssessmentContext();

  const scores = useMemo(() => {
    return new FrameworkItemScores(
      item,
      currentOverallMaturityLevel === MaturityLevel.ALL ? MaturityLevel.TARGET : (currentOverallMaturityLevel as any),
    );
  }, [item, currentOverallMaturityLevel]);

  const setBackgroundColor = (value: number) => {
    if (value > 80) {
      return classes.bgGreen;
    }
    if (value > 50) {
      return classes.bgYellow;
    }
    return classes.bgRed;
  };

  const makeTitle = (item: FrameworkItemWithScores) => {
    if (item.identifier && item.name) {
      if (item.identifier === item.name) {
        return truncate(item.name, 50);
      } else {
        return (
          <>
            <Typography fontSize="10px">{item.identifier}</Typography>
            <Typography fontSize="10px">{truncate(item.name, 50)}</Typography>
          </>
        );
      }
    }
    return truncate(item.name, 50);
  };

  let score: ScoreMeta;
  let scoreText = '';
  let scoreValue = 0;
  if (currentOverallMaturityLevel === MaturityLevel.ALL) {
    score = scores.itemOverallMaturityScore;
    scoreText = `${score.score}%`;
    scoreValue = score.score;
  } else {
    if (currentOverallMaturityLevel === MaturityLevel.TARGET) {
      score = scores.itemOverallTargetMaturityScore;
    } else {
      score = scores.itemOverallAdvancedMaturityScore;
    }

    const { subsAchieved = 0, subsCount = 0 } = score ?? {};
    scoreValue = round(divideSafely(subsAchieved, subsCount), 2) * 100;
    scoreText = `${subsAchieved}/${subsCount}`;
  }

  const label = score?.label || 'Not Achieved';

  return (
    <div className={clsx(classes.cell)}>
      <Box className={classes.cellBackground} bgcolor="darkBlue3.main" onClick={() => setCurrentItem(item)}>
        <div className={classes.tileContainer}>
          <div className={classes.tileText}>{makeTitle(item)}</div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 8, marginBottom: 8 }}>
            {currentOverallMaturityLevel !== 'all' && (
              <Chip label={label} color={label === 'Achieved' ? 'green2' : 'orange1'} size="small" />
            )}
          </div>
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
              <LinearProgress
                variant="determinate"
                value={scoreValue}
                style={{ height: '15px', flex: 1 }}
                classes={{
                  determinate: classes.progressBar,
                  bar1Determinate: setBackgroundColor(scoreValue),
                }}
              />
              <Typography>{scoreText}</Typography>
            </div>
          </div>
        </div>
      </Box>
      <div className={classes.expandCell} />
    </div>
  );
}
