import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { formatDateWithTime } from '@sightgain/core/dates';
import { ModalStore, useModal, useModalStore } from '../../../../reusables/BetterModal';
import ObjectiveQuestions from './components/ObjectiveQuestions';
import EvidenceUpload from './components/Upload';
import { Objective } from './interface';

interface EvidencesProps {
  objective: Objective;
}

function Evidences({ objective }: EvidencesProps) {
  if (!objective.evidence.length) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="textStyle5">No evidence provided</Typography>
      </div>
    );
  }

  return (
    <>
      {objective.evidence.map(evidence => (
        <Grid item key={evidence.id} bgcolor="darkBlue3.main" padding={2} margin={1}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <span style={{ marginRight: '20px' }}>
                <FontAwesomeIcon icon={['fal', 'file']} size="lg" />
              </span>
              {evidence.fileName}
            </Grid>
            <Grid item>
              <Typography color="grey2.main">{formatDateWithTime(evidence.createdAt)}</Typography>
            </Grid>
            <Grid item>
              <Typography color="grey2.main">example.email@email.com</Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
}

interface ManualAssessmentFormProps {
  objective: Objective;
  isReadonly?: boolean;
}

export default function ManualAssessmentForm({ objective, isReadonly }: ManualAssessmentFormProps) {
  const createModal = useModalStore((s: ModalStore) => s.createModal);
  const UploadEvidenceModal = createModal('uploadEvidence');
  const { openModal } = useModal('uploadEvidence');

  return (
    <Box bgcolor="darkBlue1.main" padding={4} border="solid 1px #5d6e80">
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography style={{ fontWeight: 600, fontSize: '20px', lineHeight: '25px' }}>
            {objective.identifier} {objective.name}
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ fontWeight: 400, fontSize: '16px', lineHeight: '20px', color: '#8797AB' }}>
            {objective.description}
          </Typography>
        </Grid>
      </Grid>

      <ObjectiveQuestions objective={objective} isReadonly={isReadonly} />

      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography style={{ fontWeight: 700, fontSize: '14px', lineHeight: '17.5px' }}>Evidences</Typography>
            </Grid>
            <Grid item>
              <Button
                disabled={isReadonly}
                component={Link}
                onClick={() => {
                  openModal();
                }}
                color="primary"
                startIcon={<FontAwesomeIcon icon={['fal', 'upload']} />}
              >
                Upload Evidence
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Evidences objective={objective} />
          </Grid>
        </Grid>
      </Grid>
      <UploadEvidenceModal>
        <EvidenceUpload objective={objective} />
      </UploadEvidenceModal>
    </Box>
  );
}
