import { FormEvent, MouseEvent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridPaginationModel, GridRow, GridSortModel } from '@mui/x-data-grid';
import appStore from '../../../../../AppStore';
import { jobsService } from '../../../../services';
import { FrameworkItem, PaginationInput } from '../../../../services/interfaces';
import ControlModal from '../Manager/EvaluationWindow/Steps/Controls/ControlModal';
import RecentlyRunDetails from './RecentlyRunDetails';
import useJobsWatcher from './useJobsWatcher';
import useRecentlyRunColumns from './useRecentlyRunColumns';

const useStyles = makeStyles(theme => ({
  root: {
    '&.MuiDataGrid-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.07)',
      fontSize: theme.typography.body1.fontSize,

      '& .MuiDataGrid-columnHeader': {
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },
        '&:focus': {
          outline: 'none',
        },
        '&:focus-within': {
          outline: 'none',
        },
      },
      '& .MuiDataGrid-row': {
        '&:hover': {
          backgroundColor: '#161926',
        },
        '& .MuiDataGrid-cell': {
          '&:focus': {
            outline: 'none',
          },
          '&:focus-within': {
            outline: 'none',
          },
        },
      },
    },
  },
}));

const jobFields = `
{
  id status isExcluded createdAt
  frameworkControls {
      id
      identifier
      name
    }
  name
  evaluationId 
  trainings { 
    id isReleased status analyst { id email } createdAt isExam
    score
    eventScore
    responseScore
    meanResponseTime
    precisionScore
    instructorScore
  } 
}`;

export default function RecentlyRunEvaluations({
  type,
  status,
}: {
  type: '' | 'live-tests' | 'live-fire-training' | 'exam';
  status: string;
}) {
  const classes = useStyles();
  const history = useHistory();
  const [pagination, setPagination] = useState<Required<PaginationInput>>({
    offset: 0,
    limit: 25,
    sortBy: 'createdAt',
    sortDirection: 'desc',
  });

  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const { jobs, count, loading } = useJobsWatcher({
    fields: jobFields,
    type,
    status,
    pagination,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [frameworkControls, setFrameworkControls] = useState<FrameworkItem[]>([]);
  const [frameworkControlsJobId, setFrameworkControlsJobId] = useState<string>('');

  const handleOnExpandRow = useCallback(
    (id: string) => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setExpandedRows(prev => {
        if (prev.includes(id)) {
          return prev.filter(x => x !== id);
        }
        return [...prev, id];
      });
    },
    [],
  );

  const columns = useRecentlyRunColumns(handleOnExpandRow, expandedRows);

  const handlePaginationModelChange = ({ page, pageSize }: GridPaginationModel) => {
    setPagination({
      ...pagination,
      offset: page * pageSize,
      limit: pageSize,
    });
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length === 0) {
      return;
    }

    const sort = sortModel[0];

    setPagination({
      ...pagination,
      offset: 0,
      sortBy: sort.field === 'analyst' ? 'training.analyst.email' : sort.field,
      sortDirection: sort.sort ?? 'asc',
    });
  };

  const handleOpen = (e: FormEvent, id: string, controls: FrameworkItem[]) => {
    e.preventDefault();
    setFrameworkControls(controls);
    setFrameworkControlsJobId(id);
    setOpen(true);
  };

  const handleSave = async () => {
    try {
      await jobsService.setFrameworkControls(
        frameworkControlsJobId,
        frameworkControls.map(x => x.id),
      );
    } catch (err) {
      appStore.error(err);
    }
  };

  return (
    <div>
      <DataGrid
        autoHeight
        loading={loading}
        className={classes.root}
        columns={columns}
        rows={jobs}
        paginationMode="server"
        paginationModel={{ pageSize: pagination.limit, page: pagination.offset / pagination.limit }}
        onPaginationModelChange={handlePaginationModelChange}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        onRowClick={({ row }) => history.push(`/curriculum/evaluations/${row.type}/${row.id}`)}
        rowCount={count}
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No Evaluations Found
            </Stack>
          ),
          row: params => (
            <>
              <GridRow {...params} />
              <RecentlyRunDetails
                row={params.row}
                expanded={expandedRows.includes(params.row.id)}
                handleOpen={handleOpen}
              />
            </>
          ),
        }}
      />
      <ControlModal
        open={open}
        onClose={() => setOpen(false)}
        onSave={handleSave}
        selectedControls={frameworkControls}
        setSelectedControls={setFrameworkControls}
      />
    </div>
  );
}
