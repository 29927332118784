import { useContext, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useAbort } from '../../../../../effects';
import { frameworksService } from '../../../../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useInput from '../../../../reusables/UseInput';
// import { isGreaterVersion } from '../../../../../utilities';
import { AssessmentView, AssessmentViewProps } from './interfaces';
import { AssessmentContext } from '../AssessmentContext';
import { Button, Chip, Grid, MenuItem, Radio, Select, TextField, Typography } from '@mui/material';
import { FrameworkSet } from '../../../../services/interfaces';

const useStyles = makeStyles(theme => ({
  header: {
    padding: '0px 20px',
  },
  input: {
    borderRadius: '3px',
    border: 'solid 1px #61788e',
    '& > label': {
      padding: '0px 20px',
    }
  },
  row: {
    padding: '20px 10px',
    '& + &': {
      borderTop: `1px solid ${theme.palette.grey1.main}`
    },
    '& > .MuiGrid-container': {
      alignItems: 'center',
    }
  },
  frameworkSection: {
    padding: '20px 40px',
    alignItems: 'center',
    background: theme.palette.darkBlue2.main,
    marginLeft: '-20px',
    marginTop: '40px',
    width: 'calc(100% + 40px)',
  },
  footer: {
    borderTop: `1px solid ${theme.palette.grey1.main}`,
    padding: '20px',
  }
}));

export default function Create({ onChangeView, onExit }: AssessmentViewProps) {
  const classes = useStyles();
  const [frameworks, setFrameworks] = useState<FrameworkSet[]>([]);
  const [selectedFrameworks, setSelectedFrameworks] = useState<Record<string, boolean>>({});
  const [fwType, setFwType] = useState('all');
  const { value: assessmentName, bind: bindAssessmentName } = useInput('');
  const { setEditAssessment } = useContext(AssessmentContext);

  const performNext = () => {
    setEditAssessment(prev => ({
      ...prev,
      name: assessmentName,
      framework: frameworks.find(f => f.name === Object.keys(selectedFrameworks)[0])!,
    }));
    onChangeView(AssessmentView.LICENSES);
  };

  const handleCheckboxChange = (fw: any) => (event: any) => {
    setSelectedFrameworks({
      [fw.name]: event.target.checked,
    });
  };

  useAbort(() => {
    return frameworksService.listFrameworkSets();
  }, frameworks => {
    setFrameworks(frameworks);
  }, []);

  const getChipColor = (isSystem: boolean) => {
    return isSystem ? 'blue2' : 'green2';
  };

  const filterAlphabetically = (frameWorksSet: FrameworkSet[]) => {
    return frameWorksSet.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
  };

  const filteredFrameworks = useMemo(() => {
    if (fwType === 'all') {
      return filterAlphabetically(frameworks);
    }

    return filterAlphabetically(
      frameworks.filter(fw => fwType === 'system' ? fw.isSystem : !fw.isSystem)
    );

}, [frameworks, fwType]);

return (
  <Grid container flexDirection='column'>
    <Grid item display='flex' flexDirection='column'>
      <Grid container gap={2} flexDirection='column' className={classes.header}>
        <Grid item>
          <Typography variant='textStyle9'>Create Assessment</Typography>
        </Grid>
        <Grid item>
          <Typography color='grey2.main'>Name</Typography>
          <TextField
            autoFocus
            className={classes.input}
            required
            fullWidth
            variant='standard'
            margin='normal'
            type='text'
            placeholder='Assessment Name'
            {...bindAssessmentName}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Grid container justifyContent='space-between' className={classes.frameworkSection}>
        <Grid item flex={2}>
          <Typography variant='textStyle4'>Select Framework</Typography>
        </Grid>
        <Grid item flex={1}>
          <Select fullWidth value={fwType} onChange={e => setFwType(e.target.value)}>
            <MenuItem value='all'>All</MenuItem>
            <MenuItem value='cloned'>Cloned</MenuItem>
            <MenuItem value='system'>System</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Grid container flexDirection='column'>
        <Grid item>
          <Grid container flexDirection='column' justifyContent='space-between'>
            {
              filteredFrameworks.length === 0
              && <Typography variant='textStyle9' align='center' padding={5}>No Frameworks Available</Typography>
            }
            {
              filteredFrameworks.map(fw => {
                return (
                  <Grid item key={fw.name} className={classes.row}>
                    <Grid container gap={4}>
                      <Grid item>
                        <Radio
                          onChange={handleCheckboxChange(fw)}
                          name='framework'
                          value={fw.id}
                          checked={!!selectedFrameworks[fw.name]}
                        />
                      </Grid>
                      <Grid item>
                        <Chip label={fw.isSystem ? 'System' : 'Cloned'} color={getChipColor(fw.isSystem)} />
                      </Grid>
                      <Grid item flex={2} display='flex' alignItems='center'>
                        <Typography variant='textStyle1'>{fw.title}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item display='flex' justifyContent='end' className={classes.footer} gap={4}>
      <Button
        variant='contained'
        color='primary'
        onClick={performNext}
        endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
        disabled={!assessmentName || Object.keys(selectedFrameworks).length === 0}
      >Next</Button>
      <Button color='orange1' variant='text' onClick={onExit} sx={{
        padding: '5px 15px',
      }}>Cancel</Button>
    </Grid>
  </Grid>
);
}